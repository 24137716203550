<template>
  <section class="edit-category">
    <Loader :loading="showLoader" />
    <div class="row">
      <div class="col-lg-12">
        <div class="profile card card-body px-3 pt-3 pb-0 mb-0">
          <div class="profile-head">
            <div class="photo-content">
              <div class="cover-photo rounded"></div>
            </div>
            <div class="profile-info" v-if="user">
              <div class="profile-photo">
                <img
                  v-if="user.image_id"
                  :src="`${imageApiURL}/${bannerImg.thumb_s}`"
                  class="img-fluid rounded-circle"
                />
                <avatar
                  v-else
                  :username="user.first_name"
                  background-color="#eb2027"
                  class="rounded-circle"
                  :size="88"
                >
                </avatar>
              </div>
              <div class="profile-details">
                <div class="profile-name px-3">
                  <h4 class="text-primary mb-0 text-capitalize">
                    {{ user.first_name }} {{ user.last_name }}
                  </h4>
                  <p class="text-capitalize">{{ user.role[0] }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card mt-4">
          <form class="form-horizontal">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-centered table-nowrap" v-if="user">
                  <tbody>
                    <tr>
                      <td>First Name</td>
                      <td class="text-capitalize">{{ user.first_name }}</td>
                    </tr>
                    <tr>
                      <td>Last Name</td>
                      <td class="text-capitalize">{{ user.last_name }}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{{ user.email }}</td>
                    </tr>
                    <tr>
                      <td>Username</td>
                      <td class="text-capitalize">{{ user.username }}</td>
                    </tr>
                    <tr>
                      <td>Role</td>
                      <td class="text-capitalize">{{ user.role[0] }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
import { userApi } from "../../api";
import Avatar from "vue-avatar";
import Loader from "../../components/Loader";
export default {
  name: "user-info",
  components: {
    Loader,
    Avatar,
  },
  data() {
    return {
      user: "",
      showLoader: false,
      imageApiURL,
      bannerImg: "",
    };
  },
  mounted() {
    this.listById();
  },
  methods: {
    async listById() {
      this.showLoader = true;
      const { data } = await userApi.listById(this.$route.params.id);
      this.user = data.data.data;
      if (this.user.image_id) {
        this.avater();
      }
      this.showLoader = false;
    },
    avater() {
      this.showLoader = true;
      axios.get(`${mediaApi}/files/${this.user.image_id}`).then((response) => {
        this.bannerImg = response.data.data[0];
        this.showLoader = false;
      });
    },
  },
};
</script>
<style>
.photo-content .cover-photo {
  background: url(../../assets/images/banner.jpg);
}
</style>
